.title-menu-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    background-color: #FFFFFF;
    /* border-bottom: solid 1px rgb(204, 203, 203); */
     position: sticky;
    top:70px;
    background-color: #ffff;
    z-index: 10;
    padding: 10px 0px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.10);
}

body {
    /* background-color: #f6f6f6; */
}

.menu-ul {
    display: flex;
    list-style: none;
    font-size: 18px;
}

.menu-ul > li {
    display: inline-block;
    margin-right: 20px;
    color: black;
}
.pre-pub-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    
}
.preview-btn {
    margin-right: 50px;
}
.create-section {
    display: flex;
    flex-direction: row;
}

.side-icon-menu-bar {
    display: flex;
    flex-direction: column;
    width: 60px;
    min-width: 60px;
    align-items: center;
    padding-top: 30px;
    height: 100vh;
    background-color: #FFFFFF;
    border-right: solid 1px rgb(204, 203, 203);

}
.side-bar-2 {
    width: 300px;
    padding-top: 30px;
    height: 100vh;
    background-color: #FFFFFF;
    border-right: solid 1px rgb(204, 203, 203);

}

.side-icon-menu-bar * {
    margin-bottom: 30px;
    color: #2191FF;
    height: 30px;
    width: 30px;
}
.createSurvey-home{
    /* display: flex; */
    height: 80vh;
}

/* .menu-ul > li::after {
    content: "->";
    font-weight: normal;
    font-family: "Mateo";
    font-size: 12px;
    display: inline-block;
    color: #DADAD8;
    vertical-align: middle;
    margin-left: 8px;
    margin-right: -8px;
} */

.qc-container{
    display: block;
    width: 100%;
    position: relative;
    padding: 0.5em 0;
    background-color:aqua;
}
/* .Survey-title
{
    width: 40px;
    background-color: aquamarine;
} */
@media only screen and (max-width: 768px) {

    .title-menu-bar {
        display: flex;
        flex-direction: column-reverse !important;
        
    }
    .header-right-btns{
        display: none !important;
    }
    
}
