.report-response-table {
  /* display: flex; */
  /* width: 90%; */
  /* margin: auto; */
  /* border-radius: 10px; */
  /* box-shadow:0px 2px 6px grey; */
  /* overflow-y: scroll; */
  /* border: 1px solid #dddddd; */

  /* align-items: center; */
  max-height: 70vh;
  overflow: scroll;
}
.report-response-table > table {
  border-collapse: collapse;
  /* position: absolute; */
  width: 100%;
  /* margin-left: 20px; */
  border-spacing: 10px;
}
thead th {
  position: sticky;
  top: 0px;
  z-index: 2;
  background-color: var(--blue);
  color: white;
  /* font-size: x-small; */
  padding: 10px 20px;
  white-space: nowrap;
}

td,
th {
  border: 1px solid #dddddd;
  padding: 10px 20px;
  /* font-size: xx-small; */
  border-color: transparent;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}
/* tr:hover {
  background-color: #ddd;
} */

.response-drawer-heading {
  font-size: large;
  font-weight: bold;
  margin-top: 5px;
}

.right-drawer-question {
  margin-left: 10px;
  color: gray;
}


.answer-item {
  /* display: inline-block; Display answer choices in a row */
  white-space: nowrap; /* Prevent line breaks in answer choices */
  margin-right: 5px; 
  margin-bottom: 5px;
  /* Add spacing between answer choices */
  /* border-bottom: 1px solid #ccc; */
}

.answer-item:last-child {
  margin-right: 0; /* Remove margin for the last answer choice */
}

.ellipsis-cell {
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.full-text {
  white-space: normal;
  overflow: visible;
  text-overflow: unset;
}

/* .q-answer-text {
  max-width: 200px; /* Set the maximum width you desire */
  /* overflow: hidden;

  white-space: normal;
} */ 




