.add-quest-btn{
    font-size: 18px;
    margin: auto !important;
    
}
.quest-container{
    /* center */
    /* border-radius: 5px; */
    /* border: 1px solid #b6b7b8; */
    padding: 10px 10px;
    margin: 10px 0px;
    max-width: 90%;
   
/* background: rgba(43, 20, 173, 0.59);  */

/* border-radius: .375rem; */
/* box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.5); */
box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.15); /* Add a 3D box-shadow effect */

backdrop-filter: blur(3.7px);
-webkit-backdrop-filter: blur(3.7px);
border: 1px solid rgba(255, 255, 255, 0.78);
transition: transform 0.3s ease; 

}
.quest-container:hover {
    transform: scale(1.05); /* Zoom in on hover */
    /* border-color: #001D3D; */
    
  }

.survey-main-container{
        /* display: grid;
        grid-template-columns: auto auto auto; */
        display: flex;
        flex-direction: row;
    /* padding: 50px 30px; */
    width: 100%;
    margin: auto;
    position: relative;
    /* background-color: aqua; */
    margin-top: 1%;
}
 .ques-access{
      
    padding: 20px 20px;
    background: rgba(255, 255, 255, 0.59);
border-radius: .375rem;
box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.75);
backdrop-filter: blur(3.7px);
-webkit-backdrop-filter: blur(3.7px);
border: 1px solid rgba(255, 255, 255, 0.78);
margin: 10px;
    /* width: 40%; */
    /* background-color: #f3f3f4; */
}
.survey-right-container, .survey-left-container{
    padding: 20px 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.15);
    /* background: rgba(255, 255, 255, 0.59); */
/* border-radius: .375rem;
box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.75); */
backdrop-filter: blur(3.7px);
-webkit-backdrop-filter: blur(3.7px);
/* border: 1px solid rgba(255, 255, 255, 0.78); */
margin: 10px;
    /* background-color: chartreuse; */
}
.survey-right-container, .survey-left-container, .ques-access{
    height: 650px;
    overflow-y: scroll;
    /* background-color: rgb(255, 127, 202); */
    /* max-width: 15%; */

    /* background-color: white; */
    /* background-color: var(--bggray); */
}
.survey-right-container{
    /* center bg color */
width: 25%;
/* background-color: rgb(0, 30, 255); */
/* margin: auto; */
}
.survey-left-container{
    /* width: 30%; */
    position: relative;
    overflow-y: none;
    order: 3;
    flex-grow: 1;
    /* background-color:brown; */
    width:60%;
    /* padding-bottom: 0px !important; */
    }
.quest-builder-form{
width: 100%;
}
.quest-builder-form button{
 background-color: var(--blue);
 color: #fff;
 border: none;
 border-radius: 5px;
 padding: 10px;
 margin: 5px 0px;
}
.field-container{
display: flex;
flex-direction: column;
margin: 10px 0px;
}
/* .field-container input, .field-container select {
padding: 5px 5px;
margin: 5px 0px;
font-size: 14px;
background-color: #fff;
outline: none;
border: none;
border-radius: 5px;
} */

.quest-title{
    background-color: #fff;
    font-weight: 600;
    color: var(--blue);
    font-size: 16px;
    padding: 5px;
    border-radius: 3px;
    border-bottom: #b6b7b8 2px solid;
}
.row-text{
    font-size: 14px;
    color: #4e4e4e;
    padding: 3px 0px;
}
.row-text>span{
    font-size: 16px;
    color: #161616;
    font-weight: 600;
    text-transform: capitalize;
}
.btn-container{
    /* padding: 10px 0px; */
    margin: 10px 0px 0px 0px;
    text-align: center;
    
}
.btn-container button{
    color: #000;
    background-color: var(--green);
    padding: 5px;
    border: none;
    border-radius: 5px;
    margin: 0px 10px;
    font-size: 14px;
    width: 60px;
    letter-spacing: 1px;

}
.choice-container, .skip-logic-container{
display: flex;
flex-direction: row;
align-items: center;
background-color: #fff;
border-radius: 5px;
border: 1px solid #939393;
/* height: 30px; */
margin: 5px 0px;
}
.del-btn{
    padding: 0px !important;
    margin: 2px !important;
}
.choice-container>input{
    flex: 1;
    outline: none;
    border: none;
    padding: 5px;
    margin: 2px !important;
}
.survey-questions-container {
    border: 1px solid #ccc;
    padding: 10px;
  }

.skip-logic-container>select{
/* max-width: 75%; */
flex: auto;
margin-bottom: 0px !important;
margin-right: 5px;
border: none;
}
.row-container{
    margin: 10px 0px;
}
.choice-grid{
    display: grid;
    grid-template-columns: auto auto;
}

.sticky-bot-container{
    position: sticky;
bottom: 0;
background-color: #fff;
width: 90%;
/* padding: 5px 0px; */
border-radius: 5px;
/* height: max-content; */
background-color: #fff;
padding: 10px 0px;
}
/* .sticky-bot-container>button{
    /* margin: 0px !important;
} */

.ques-access{
    /* left bgcolor */
    display: flex;
    flex-direction: column;
    width: 20%;
    /* background-color: aqua; */
    /* border-right: 2px solid #A6A8AB;
    border-left: 2px solid #A6A8AB; */
}
.quest-title-prev{
    white-space: nowrap;
    min-width: 80px;
max-width: 80%;
overflow: hidden;
text-overflow: ellipsis;
display: inline-block;
}
.quest-access-btn{
    /* left............. */
    padding: 5px;
    margin: 10px 5px;
    border-radius: 5px;
    border: 1px solid #b6b7b8;
    text-decoration: none;
    color: var(--blue);
    /* background-color: #fff; */
    position: relative;
background: rgba(255, 255, 255, 0.59);
border-radius: .375rem;
box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.75);
backdrop-filter: blur(3.7px);
-webkit-backdrop-filter: blur(3.7px);
border: 1px solid rgba(255, 255, 255, 0.78);
}
.del-ed-btn{
    right: 5px;
    position: absolute;
}
.del-ed-btn button, .del-btn{
    background-color: transparent !important;
    padding: 2px;
    margin: 2px;
    
}
.del-ed-btn button>svg{
    height: 15px;
    width: 15px;
    /* fill: #939393; */
}
.del-ed-btn button:hover > svg path{
    /* fill: var(--blue); */
    fill: #fff;
}
.del-btn:hover > svg path{
    fill: var(--blue)
}
.quest-access-btn:hover {
    color: #fff;
    background-color: var(--blue);
    
  }
  .empty-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    /* border: 1px solid #4e4e4e; */
    background-color: #fff;
    margin: auto;
    padding: 5px;
  }

.quest-builder-btns{
    width: 50%;
 margin-left: 60%;
 /* background-color: var(--blue); */
 color: var(--blue);
}

.choices-container{
    display: grid;
    grid-template-rows: auto;
    column-gap: 2px;
}


  /*Check box to switch */

  .switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 25px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 16px;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
  }
  
  input:checked + .slider {
    background-color: var(--green);
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px var(--green);
    box-shadow: 0 0 1px var(--green);
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(20px);
  }
  

.align-right{
    text-align: right;
}
.overflow{
    overflow: hidden;
    text-overflow: ellipsis;
}

.prev-modal{
    position: fixed;
right: 100px;
    z-index: 100;
    top: 90px;
}

@media only screen and (max-width:1200px){
    .survey-main-container{
        display: flex;
        flex-direction: column;
        padding: 10px 10px 50px 10px;
        align-items: center;
    }
    .survey-right-container, .survey-left-container, .ques-access{
        height: 450px;
        overflow-y: scroll;
        background-color: var(--bggray);
        width: 80%;
    }
    .ques-access{
        height: 200px !important;
        order: 2;
    }
    .survey-right-container{
        order:3;
    }
    .survey-left-container{
        order: 1 !important;
        padding-bottom: 0px !important;
    }
    .sticky-bot-container{
        background-color: #fff;
        padding: 10px 0px;
    }
    .choice-grid{
        display: grid;
        grid-template-columns: auto;
    }
}
@media only screen and (max-width: 1200px ) and (min-width:767px) {
    .ques-access{
        height: 200px !important;
        order: 1;
    }
}