
.share-link-section{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    width: 100%;
} 

.copy-text-input{
height: 40px;
width: 100%;
color: #000;
font-weight: 400;
font-size: 16px;
line-height: 20px;
border-radius: 4px;
border: 2px solid #d2d0d0;
background-color: #fff;
transition: border 0.3s;
overflow: hidden;
text-overflow: ellipsis;
padding: 0 0.5em;
}

/* .copy-text-input:focus{
    border-color: #645FFF;
} */

.Copy-Button{
    background-color: var(--green) !important;
    color: #000 !important;
    height: 55px;
/* width: 100%; */
border-radius: 4px;
border: none ;

}
.copy-text-box{
    height: 50px !important;
    /* background-color: #645FFF !important; */
    padding: 1.5px !important;
}

.mailcard, .social-share-icons{
 display: flex;
 /* flex-direction: column; */
 grid-template-columns: auto auto auto auto auto;
 justify-content: center;
 text-align: center;
 gap: 30px;
 align-items: center;
 flex-wrap: wrap;
 margin-top: 50px;
 /* position: absolute;
 top: 300px; */

}

.share-card{
    height: 150px;
    width: 175px;
    border: 1px solid var(--blue);
    border-radius: 5px;
    /* padding: auto; */
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffff;
    box-shadow: 0px 4px 8px rgb(0 0 0 / 5%);
}
.share-card>a>figure>svg, .social-share-icons>a>svg{
    fill: var(--blue);
    width: 40px;
    height: 40px;
}
.social-share-icons>a>svg{
    
    height: 50px;
    width: 50px;
}

.share-card:hover>a>figure>svg{
    transform: scale(1.3);
}
.share-card:hover{
    background-color: var(--green);
}
.social-share-icons>a>svg:hover {
    fill:var(--green)
}

a:link, a:hover, a {
    
    text-decoration: none;
}
.bg-c-linkedin {
    fill: var(--green);
}
/* For iPhone SE */
@media only screen and (max-width: 320px) {
    .share-link-section{
        display: flex;
        flex-direction: column;
        gap:.5rem;
        position: absolute;
        height: 100vh;
    }
    .mailcard{
        display: flex;
        gap:2rem;
        margin-top: 190%;
        
    }
    .copy-link{
        position: absolute;
        top:0px;
        display: flex;
        gap:5px;
        
    }
    .share-cards{
        padding:2.5rem;
        display: flex;
        flex-wrap: wrap;
    }
    
}

