 .overview-insight
{
    /* height: 290px; */
    width:90%;
    background-color: white;
    box-shadow:0px 2px 6px grey;
    border-radius:16px;
    /* margin-top: 25px; */
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-left: 0px; */
    margin: 20px auto;
    flex-direction: row;
    padding: 20px;
  /* gap:50px; */    
}



.overview-card{
  color: #e5e4e2;
}
.overview-card svg{
  fill: #e5e4e2;
}
.card1
{
  height:180px;
  width:150px;
  /* background:linear-gradient(  var(--green), var(--blue)); */
  background-color: var(--blue);
  /* margin-left: 20px; */
  /* margin-top: 30px; */
   border-radius: 12px;
   transition: transform .1s;
   display: flex;
   /* flex-direction: row; */
   align-items: center;
   justify-content: center;
   box-shadow: 2px 2px 4px 2px grey;
}
.card1:hover, .card2:hover, .card3:hover, .card4:hover, .card5:hover{


  transform: scale(1.1); /* IE 9 */
  transform: scale(1.1); /* Safari 3-8 */
  transform: scale(1.1); 

}
.card1-heading, .card2-heading, .card3-heading, .card4-heading
{
  
  font-size: larger;
  font-weight: 400;
  
  
  
  
  
}
.card2
{
  height:180px;
  width:150px;
  /* background:linear-gradient( var(--green), var(--blue)); */
  background-color: var(--blue);
   border-radius: 12px;
   transition: transform .1s;
   display: flex;

   align-items: center;
   justify-content: center;
   box-shadow: 2px 2px 4px 2px grey;
}




.card3
{
  height:180px;
  width:150px;
  background-color: var(--blue);
  /* background:linear-gradient( var(--green), var(--blue)); */
   border-radius: 12px;
   transition: transform .1s;
   display: flex;
   
   align-items: center;
   justify-content: center;
   box-shadow: 2px 2px 4px 2px grey;
}



.card4{
   height:180px;
   width:150px;
   background-color: var(--blue);
   /* background:linear-gradient( var(--green), var(--blue)); */
   border-radius: 12px;
   transition: transform .1s;
   display: flex;
   align-items: center;
   justify-content: center;
   box-shadow: 2px 2px 4px 2px grey;
}




  .overview-card{
    display: flex;
    margin: auto;
    align-items: center;
    justify-content: center;
    gap:25px; 
    flex-direction: row;   
  }
  .card5{
    height:180px;
    width:150px;
    background-color: var(--blue);
    /* background:linear-gradient( var(--green), var(--blue)); */
     border-radius: 12px;
     transition: transform .1s;
     display: flex;
  
     align-items: center;
     justify-content: center;
     box-shadow: 2px 2px 4px 2px grey;
  }
  
  @media only screen and (max-width:768px){
    .overview-insight{
      display: flex;
      flex-direction: column;
      
    }
    .overview-card{
      display: flex;
      flex-direction: column;
      
    }
   
   .card{

    /* background-color: aqua; */
   }
   .container{
    width: 100%;
  
   }
  }
  

  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 0 20px;
  }
  
  .card {
    width: 70%;
    height: 550px;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    margin-bottom: 10%;
  }
  
  .chart-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    
  }
 
  /* .chart-title {
    margin-bottom: 10px;
    font-weight: bold;
  } */
  
  .no-response {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .top-div {
    position: absolute;
    top: 20px;
    font-size: large;
  }
 
 
  