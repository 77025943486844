

.center-nav-menu {
    display: flex;
    flex-direction: row;
    /* gap: 10px; */
    border: solid 2px var(--blue);
    /* padding: 10px; */
    border-radius: 5px;
}

.header-menu {
    padding: 10px;
    border-right: var(--blue) solid 1px;
    cursor: pointer;
}

.header-menu.active, .header-menu:hover {
    background-color: var(--blue);
    color: #ffff;
        /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 1px 1px 10px 3px rgba(0, 0, 0, 0.25); */

}

.header-right-btns {
    display: flex;
    flex-direction: row;
    gap: 20px;
    /* justify-content: center; */
    /* align-items: center; */
}

.header-right-btns  {
padding: 10px;
}

.prev-btn, .publish-btn {
    background-color: var(--green);
    /* height: 30px;
    width: 30px; */
    padding: 8px;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    transition: transform 0.3s ease; 
}
.prev-btn:hover {
    transform: scale(1.05);
    /* background-color: #e0e0e0;  */
  }
/* .title-menu-bar{
    position:fixed;
    width:100%;
} */
.survey-title{
    display: flex;
    gap: 5px;
}
