/* * {
 margin: 0;
 padding: 0;
box-sizing: border-box;
} */

/* body{
    text-align:center;
    background-color: #1b2034;
    color:#fff;
} */
.QrCode>input{
    appearance: none;
    outline: none;
    border: none;
    background:#eee;
    width: 60%;
    font-size: 1rem;
    max-width: 300px;
    padding: 10px;
    border-radius: 0.5rem;
    margin-right: 1rem;
    margin-bottom: 20px;
} 

button{

font-size: 1rem;
padding: 10px;
background-color: var(--green);
border-radius: 5px;
border: none;
/* margin: 2px; */
}
