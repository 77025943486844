.loader {
    font-size: 25px;
    /* font-weight: bold; */
    text-align: center;
    margin-top: 20px;
  }
  
  .dot1,
  .dot2,
  .dot3 {
    animation-name: dot;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    display: inline-block;
    padding: 0 5px;
  }
  
  .dot2 {
    animation-delay: 0.5s;
  }
  
  .dot3 {
    animation-delay: 1s;
  }
/* Add this CSS to your existing styles or create a new CSS file */
.survey-card {
  /* Other styles for the card */
  transition: background-color 0.3s ease; /* Smooth transition effect */
}

.survey-card:hover {
  background-color: #e0f3e0; /* Change this to the desired hover background color */
}




  
  .search-bar {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 3.5px;
    transition: width 0.5s ease-in-out,;
    width: 45px;
    padding: 3.5px;
   
   
  }
  
  .search-bar input {
    border: none;
    outline: none;
    padding: 5px 10px;
    width: 100%;
  }
  
  .search-bar button {
    background: transparent;
    border: none;
    color: #888;
    cursor: pointer;
    padding: 3px;
  }
  
  .search-bar.expanded {
    width: 400px;
    box-shadow: 0 0 3px 2px var(--blue);
  }
  
  /* .search-bar input:focus {
    box-shadow: 0 0 3px 2px var(--blue);
    border-radius: 2px;
  } */
  
.heading{
  margin-left: 10%;
  font-weight: bold;
  font-size: x-large;
}
  .Survey-heading{
    /* background-color: aquamarine; */
    display: flex;
    /* flex-direction: row;
     */
     justify-content: space-between;
     
     padding: 5px;
     
  }
  .loading-spinner {
    border: 3px solid rgba(0, 128, 0, 0.3); /* Green border with transparency */
    border-top: 3px solid #008000; /* Green border on top */
    border-radius: 50%; /* Make it round */
    width: 40px; /* Set the width */
    height: 40px; /* Set the height */
    animation: spin 1s linear infinite; /* Apply animation */
    margin: 20px auto; /* Center it horizontally */
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  @media only screen and (max-width: 320px) {
    .Survey-heading{
      /* display: flex; */
flex-wrap:wrap;
/* background-color: blue; */
margin-top: 20%;

    }
    .heading{
      display: flex;
flex-wrap:wrap;
font-size: large;
/* margin-bottom: 30px; */


    }
  }



  @keyframes countUp {
    from {
      opacity: 0;
      transform: translateY(50%);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .questions-count {
    animation: countUp 0.5s ease-in-out;
  }
  .responses{
    animation: countUp 0.5s ease-in-out;
  }

  @media only screen and (max-width: 1200px) {

    .heading{
      display: flex;
flex-wrap:wrap;


  }
  .Survey-heading{
    /* display: flex; */
    /* flex-direction: column; */
flex-wrap:wrap;
/* margin-top: 20%; */

  }
}
@media screen and (max-width: 767px){
  
 .heading{
  font-size: 15px;
  /* margin-top: 20%; */
 }
 .ul-head{
  margin-left: 5%;
  width:180%;
 }
 .created-list{
  
  margin-bottom: 10%;
  
 }
 .survey-title{
  margin-top: 15%;
  padding: 10%;
  
 }
 .Survey-heading{
  margin-top: 5%;
 }
}

  @keyframes dot {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  .questions-count {
    text-align: center;
    font-size: 16x;
  }
  
  .questions-count br {
    display: none;
  }
  
  .questions-count span {
    display: block;
    font-size: 16px;
  }
  
  .questions-count:before {
    content: "";
    display: block;
    /* margin-left: -10px;  */
  }
  
  .responses {
    /* display: flex; */
  align-items: center;
    text-align: center;
    font-size: 16px;
  }
  
  .responses br {
    display: none;
  }
  
  .responses span {
    display: flex;
    gap:2px;
    font-size: 16px;
    align-items: center;
    /* margin-left: 10px; */
  }
  
  .responses:before {
    content: "";
    display: block;
    /* margin-left: -10px; */
     /* adjust this value to move the number left */
  }
  .survey-title {
    max-width: 300px; /* adjust this value to set the maximum width */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .survey-card-col1 {
    flex: 1;
  
    max-width: 30%; 
   
    box-sizing: border-box;
  }
  
  .survey-card-col2 {
    flex: 1;
    max-width: 50%; 
    box-sizing: border-box;
  }
.survey-card-surtitle{
  display: flex;
  
}
.scratch-card {
  width: 40px;
  height: 40px;
  background-color: var(--blue);
  display: flex;
  justify-content: center;
  align-items: center;
}
.Dashboard-empty-header{
  color: gray;
}

.Dashboard-empty-header-content{
  padding: 30px;
}