/* @import url('https://fonts.googleapis.com/css2?family=Inter&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

body {
  margin: 0;
  /* font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue'; */
    font-family: 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--font);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root{
 --blue: #001D3D; 
  --green: #8cc739;
  --bggray: #f3f3f4;
  --font: #1b1c1e;
  /* --blue: #262626;
  --green: #ff7f11;
  --bggray: #e2e8ce; */
  /* --blue: #8239c7;
  --green: #f8cf05; */
  /* --blue: #23BDF3;  */
  /* --green: #58E6CD; */
  

}

header{
  position: fixed;
  top:0px;
}

/* #061d67; */
/* 134687 */

input, select, textarea, button{font-family:inherit;}

button{cursor: pointer;}

.rv-styled{
  flex-direction: row;
  display: flex;
}

.pointer:hover{
  cursor: pointer;
}

.glass{
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(3.3px);
-webkit-backdrop-filter: blur(3.3px);
border: 1px solid rgba(0, 29, 61, 0.21);
opacity: 0.9;
}

.ipstyle{
  outline: none;
border-radius: 5px;
margin: 10px 0px;
padding: 10px;
border: none;
/* border-bottom: 1px solid #939393; */
background-color: #fff;
font-size: 16px;
resize: vertical;
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.ipstyle:focus {
  outline: none; /* Remove the default outline on focus */
 
 
}
.theme-select{
  background: radial-gradient(circle, rgb(238, 174, 202) 0%, rgb(148, 187, 233) 100%);
  outline: none;
  border: none;
  width: 110px;
  height: 30px;
  margin: auto 10px;
  border-radius: 3px;
}


::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}