.survey-all-list {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  margin-top: 20px;
  width: 100%;
}

.survey-card {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  /* border: #645FFF solid 1.5px; */
  width: 70%;
  margin: 10px;
  padding: 10px;
  border-radius: 5px;
  background: #ffffff;
  box-shadow: 0px 0px 1px 2px #d1d0f7;
}

.survey-card:hover {
  border: var(--blue) solid 1.5px;
}

.survey-title {
  font-size: 20px;
  font-weight: 700px;
}
.survey-card-col2 {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 70%;
  height: auto;
}
ul {
  list-style: none;
  padding-left: 0;
  color: #5c5c5d;
  font-size: 14px;
}
li {
  padding-bottom: 5px;
}
.survey-status {
  background-color: #e8e8f9;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  height: 20px;
  width: 40px;
  font-size: 14px;

}

.questions-count,
.responses {
  color: #5c5c5d;
}
.card-drop-menu {
  background-color: var(--green);
  border-radius: 50%;
  box-shadow: 0px 0px 1px 2px #d1d0f7;
}

@media only screen and (max-width: 768px) {
 
  .survey-card {
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: flex-start;
    /* padding: 0px !important; */
    /* background-color: #f7f7fb; */
  }

  .survey-card-col1{
    width: 100%;
  }

  .survey-card-col2 {
    display: flex;
    flex-direction: row;
    width: 100%;
    /* background-color: #eaf371; */
    /* color: black; */
    padding: 5px;
    border-radius: 5px;

    /* justify-content: space-around;
        width:70%; */
  }
  .survey-status {
    position: absolute;
    align-self: auto;
    top: 0px !important;
    right: 0px;
    background-color: #e8e8f9;
    border-radius: 0px 5px 0px 0px;
    padding: 5px !important;
  }
  .questions-count{
    
    margin-left: 100px;
   
  }
  .responses{
    margin:10px;
  }
  
 
}
.title{
  font-size: larger;
  font-weight: bold;
  /* align-items: center; */
  margin-left: 40px;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.welcome-back {
  animation: fadeInAnimation 0.5s forwards;
}
.animated-typography {
  color:black; /* Initial text color */
  animation: fadeInAnimation 0.5s forwards;
}