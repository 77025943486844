
.graphbutton{
    display: flex;
    justify-content: end;
    align-items: end;
    border-radius: 8px;
    margin: 5px auto;
    padding-right: 10px;

}
.Bar{
    border-radius: 3px;
    width:45px;
    height: 45px;
    margin: auto 2px;
}
.page-footer{
    display: flex;
    flex-direction: row;
    justify-content: right;
}
.graph-media{
    display: none;
}


@media only screen and (max-width:768px){
    .graph-media{
        display: block;
    }
    .graph-desktop{
        display: none;
    }
.recharts-wrapper{
    margin: auto;
    height: 300px !important;
    width: 300px !important;

}
.recharts-wrapper>svg{
    width: 100%;
    height: 100%;
}
.recharts-legend-wrapper{
    width: 100% !important;
}
.Bar{
    height: 35px;
    width: 35px;
}
.Bar>svg{
    height: 20px;
    width: 20px;
}
.que-type{
    font-size: 20px;
}
.graph{
    display: none;
}
}
/* 
@media (max-width: 768px) {
    .recharts-wrapper {
      overflow: auto;
    }
    .recharts-cartesian-axis-tick-value {
      font-size: 10px;
    }
  } */