.container-plans{
    height: 80%;
    width: 100%;
    background-color: var(--blue);
display: flex;
flex-direction: column;

}
.navbar-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  z-index: 9999; /* Set a high z-index value to ensure it appears above other content */
}

.price-content{
 
    width: 70%;
    opacity: 0;
    transform: translateX(-100%);
    animation: fadeInFromLeft 1s forwards;

}
.text-p{
    font-size: 18px;
    letter-spacing: 2px;
}

.header-text{
    font-size: 30px;
    font-weight: bolder;
}
.cont{

display: flex;
    color: white;
}
.lapright-image{
    margin-right: 10%;
    opacity: 0;
    transform: translateX(100%);
    animation: fadeInFromRight 1s forwards;
   

}
/* Add these styles to your CSS */

.about-lapbottomimg {
    opacity: 0;
    transform: translateY(100%);
    animation: fadeInFromBottom 1s forwards;
  }
  
  @keyframes fadeInFromBottom {
    from {
      opacity: 0;
      transform: translateY(100%);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  

/* Add these styles to your CSS */

.about-topimage {
    opacity: 0;
    transform: translateY(-100%);
    animation: fadeInFromTop 1s forwards;
  }
  
  @keyframes fadeInFromTop {
    from {
      opacity: 0;
      transform: translateY(-100%);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
@keyframes fadeInFromLeft {
    from {
      opacity: 0;
      transform: translateX(-100%);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  @keyframes fadeInFromRight {
    from {
      opacity: 0;
      transform: translateX(100%);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
/* -------------------Media Query------------------------ */
  @media screen and (max-width: 767px){
.container-plans{
  margin-top: 60%;
}
.cont{
  flex-wrap: wrap;
  width: 70%;
}
.price-content{
  width: 130%;
  padding-left: 40%;
}



  }

  @media screen and (min-width: 768px) and (max-width: 1023px) {
    .container-plans{
      margin-top: 10%;
    }



  }