.testing{
    
    border-radius:6px;
    background-color:var(--green) ;
    /* margin-left: 150px;    */
    /* height: 25px; */
    /* width: 160px; */
   
/* font-size:medium;  */
display: flex;
/* background-color: rgb(193, 188, 188); */
padding: 5px;
justify-content:end;
align-items: end;


}
.share-content{
color: black;

}

.side-pannel-header
{
    display: flex;
    margin-top: 10px;
}
.side-pannel-export{
    margin-left: 10px;
    font-weight: bold;
    font-size: large;
}
.side-pannel-cancel{
    margin-left: 350px;
    font-size: small;
    cursor:default;
}
.side-pannel-subheader{
    margin-top: 25px;
    letter-spacing: 0.07em;
    color: grey;
}
/* .side-pannel-csvdwld
{
    height: 40px;
    width: 300px;
    background-color:  var(--blue);
    margin-left: 100px;
    box-shadow:0px 2px 6px grey;
    border-radius:16px;
    margin-top: 20px;
    color:white;
    text-align: center;
    padding-top: 14px;
    font-size: medium;
    
} */

.right-side-pannel{
    display: flex;
    justify-content: end;
    align-items: end;
    margin-left: 780px;
}

@media only screen and (max-width:768px){
    .right-side-pannel{
        margin-left: 20px;
    } 
}