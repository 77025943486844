
 .result-table{
    display: flex;
    width: 90%;
    margin: 10px auto;
    border-radius: 10px;
    box-shadow:0px 2px 6px grey;
    overflow-y: scroll;

    /* align-items: center; */
    height:260px ;
    
  }
  .result-table>table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    position: relative;
    width: 100%;
    /* overflow-y: auto; */
    /* justify-content: center; */
    /* border-radius: 10px; */
      }
  thead th{
    position: sticky;
    top: 0px;
    z-index: 2;
    background-color: #f5f5f5;
  }
  
  
  td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  
  }
  
  tr:nth-child(even) {
      background-color: var(--blue);
  }
  .result-table td {
    padding: 4px;
  }
  