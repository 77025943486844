
.rslt-main-contianer {
  align-items: center;
  justify-content: center;
  display: flex;
  /* background-color: aqua; */
  /* margin-left: 10%; */
  /* position: absolute; */
  
}
.result-quest-container {
  /* min-height: 60em; */
  width: 80%;
  background-color: #ffff;
  margin: 20px auto;
  box-shadow: 0px 2px 6px grey;
  border-radius: 16px;
}
.res-quest-access {
  padding: 20px;
  margin: 20px auto;
  /* width: 30%; */
  position: sticky;
  height: 400px;
  overflow-y: auto;
  top: 200px
}
.wrapper {
  border-radius: 5px;
    border: 1px solid #b6b7b8;
  background: rgba(255, 255, 255, 0.59);
border-radius: .375rem;
box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.75);
backdrop-filter: blur(3.7px);
-webkit-backdrop-filter: blur(3.7px);
border: 1px solid rgba(255, 255, 255, 0.78);
  height: 100%;
  width: 20%;
  margin-top: 3%;
}

.ques-btn {
  padding: 10px;
  width: 50%;
  margin: 10px auto;
}
.ques-btn > a {
  color: var(--blue);
  text-decoration: none;
  padding: 8px;

  background-color: var(--bggray);
  border-radius: 5px;
  border: 1px solid #ccc;
  /* margin: 10px auto; */
}
/* .res-quest-access {
  height: 68vh;
  overflow-y: auto;
} */
.rslt-right-container {
  width: 80%;
  /* background-color: aqua; */
}
.rslt-right-container > .rslt-hdr-row {
  display: none;
}
.result-header {
  /* height: 12em; */
  padding: 10px;
  color: #fff;
  /* width: 100%; */
  background-color: var(--blue);
  border-top-left-radius: 10px;
  border-top-right-radius: 16px;
  /* font-weight: 550; */
}
.rslt-hdr-row {
  padding: 5px 10px;
}
.rslt-row-spliter {
  margin: 10px auto;
}
.q-title {
  background-color: var(--blue);
  color: #fff;
  font-size: 22px;
  border-radius: 5px;
}
.Riversia-logo-img {
  /* display: flex; */
  width: 75px;
  height: 25px;

  /* margin-bottom: 60px; */
}
.Report-page{
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
}
.logo-footer {
  display: none;
  float: right;
  margin: 10px auto;
  /* flex-direction: column; */
}
.pagination {
  padding: 5px;
}
.logo-footer-content {
  color: gray;
  letter-spacing: 0em;
  /* font-size: x-small; */
  margin-left: 20px;
  margin-top: 5px;
}
summary {
  display: block;
  padding: 0.5em;
  cursor: pointer;
  /* font-weight: bold; */
}

summary::after {
  content: "\25B6";
  float: right;
  transform: rotate(90deg);
}
details[open] summary {
  background-color: #f0f0f0;
}
details[open] summary::after {
  content: "\25B6";
  float: right;
  transform: rotate(30deg);
}
details[open] {
  background-color: #fff;
}

details {
  list-style: none;
}

details {
  padding: 0.5em;
  border: 1px solid #ccc;
  background-color: var(--bggray);
}

details + details {
  margin-top: 1em;
}
.acss-btn-mob{
  display: none;
}

.report-no-data-image{
 margin-left: 5em
}
.report-no-data{
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;

}
.report-no-data-button{
  margin-left: 5em

}

.download-options{
  display: flex;
  gap:10px;
  flex-direction: end;
  align-items: end;
  justify-content: end;
}
.csvdwld-link{
  /* margin-left: 80%; */
}
@media only screen and (max-width: 320px) {
  .download-options{
    display: flex;
    flex-wrap:wrap;

  }
}
@media only screen and (max-width: 1200px) {
  .download-options{
    display: flex;
    flex-wrap:wrap;

  }
  .acss-btn-mob{
    display: block;
  }
  .result-quest-container {
    width: 95%;
    
  }
  .rslt-main-contianer {
    display: flex;
    flex-direction: column;
  
  }
  .res-quest-access {
    display: none !important;
  }
  .rslt-right-container {
    width: 100%;
    height: 100% !important;

    overflow-y: none;
  }
  .rslt-right-container > .rslt-hdr-row {
    display: block !important;
  }
  .res-quest-access-media {
    /* padding: 20px;
        margin: 20px auto; */
    width: 100%;
    display: grid;
    grid-template-columns: auto auto auto;
  }
  .ques-btn {
    width: 100%;
  }
  .q-title {
    font-size: 18px;
  }
}

