.prev-main-container{
    margin: auto;
/* width: 50%; */
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
height: 100vh;
}
.prev-opt-container{
    display: flex;
    flex-direction: column;
    margin: 10px auto;
}
.prev-opt-container button{
margin: 10px auto;
width: 60%;
}
.prev-nav-btns{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
}
.prev-nav-btns *{
    background-color: var(--blue);
    color: #fff;
    width: 100%;
    margin: auto 20px;
}
.prev-footer{
    float: right;
    /* right: 100; */
}
.prev-footer svg{
    height: 50px;
    width: 100px;
    padding-right: 30px;
}.prev-main-container{
    margin: auto;
/* width: 50%; */
display: flex;
flex-direction: column;
align-items: center;
justify-content: space-between;
height: 100vh;
/* background-color: crimson; */
}
.survey-title-live{
    width: 100%;
    background-color: var(--blue);
    color: #fff;
}
.survey-title-live h1{
    padding: 0px 20px;
}
.survey-main-form{
    width: 50%;
    /* padding: 20px 20px; */
    border-radius: 16px;
    margin-top: 10%;
    /* background-color: #03b2cb; */
    /* border: 2px solid #939393; */
}
.survey-main-form input[type=text], .survey-main-form input[type="tel"], .survey-main-form select{
    width: 100%;
    /* height: 50px; */
    padding: 10px;
    margin: 10px 5px;
    outline: none;
    font-size: 16px;
    box-sizing:border-box
}
.prev-footer{
    width: 100%;
    text-align: end;
}
.prev-opt-container{
    display: flex;
    flex-direction: column;
    margin: 20px auto;
}
.prev-opt-container button{
margin: 10px auto;
width: 60%;
}
.prev-nav-btns{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    margin: 10px auto;

}
.prev-nav-btns *{
    background-color: var(--blue);
    color: #fff;
    width: 100%;
    margin: auto 20px;
    font-weight: 600;
    font-size: 18px;
}
.prev-footer p{
    padding-right: 30px;
}
.prev-footer svg{
    height: 50px;
    width: 100px;
    padding-right: 30px;
}
.prev-nav-btns button:disabled{
    opacity: 0.8;
}

.required-error{
    color: red;
}

.input-field {
  border: none;
  border-bottom: 1px solid gray;
  transition: border-bottom 0.3s ease-in-out;
}

.input-field:focus {
  outline: none;
  border-bottom: 2px solid black;
  animation: border-grow 0.3s ease-in-out forwards;
}

@keyframes border-grow {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}



.star-rating {
  display: inline-block;
  font-size: 0;
  line-height: 0;
text-align: center;
margin: 20px auto;
}

.star-rating input[type='radio'] {
  display: none;
}

.star-rating label {
  display: inline-block;
  /* width: 1em;
  height: 1em; */
  /* margin: 0.1em; */
  padding: 0;
  cursor: pointer;
  font-size: 2rem;
  color: #bbb;
  text-shadow: 0 0 1px #333;
}

.star-rating span {
  display: inline-block;
  /* width: 2em;
  height: 2em; */
  margin: 10px 10px;
  padding: 0;
  font-size: 4rem;
  color: #797978;
  text-shadow: 0 0 1px #333;
}

.star-rating span.active {
  color: #ff0;
  text-shadow: 0 0 1px #333, 0 0 5px #ff0;
}


.nps>button{
  font-size: 1rem;
padding: 10px;
/* background-color: var(--green); */
border-radius: 3px;
margin: 2px;
height: 42px;
width: 42px;
color: white;
border: none;
opacity: .7;
}

.nps>button:nth-child(-n+6){
background-color: #ff6561;

}

.nps>button:nth-child(n+7):nth-child(-n+9){
background-color: #ffcb60;
}

.nps>button:nth-child(n+9){
background-color: #00c36e;
}
.nps{
margin: 10px auto;
}
.nps .active{
  opacity: 1;
}
.nps>button.active {
  filter: brightness(80%);
}


.loading-spinner {
  border: 3px solid rgba(0, 128, 0, 0.3); /* Green border with transparency */
  border-top: 3px solid #008000; /* Green border on top */
  border-radius: 50%; /* Make it round */
  width: 40px; /* Set the width */
  height: 40px; /* Set the height */
  animation: spin 1s linear infinite; /* Apply animation */
  margin: 20px auto; /* Center it horizontally */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media only screen and (max-width:760px){
  .survey-main-form{
    width: 80%;
  }
}











/* .PhoneInput {
    align-items: center;
    display: flex;
  }
  .PhoneInputCountry {
    align-items: center;
    align-self: stretch;
    display: flex;
    margin-right: .35em;
    margin-right: var(--PhoneInputCountrySelect-marginRight);
    position: relative;
  } */


  .selectable-button{
    background-color: #f3f3f4;
    color: #000;
    border: 1px solid #939393 !important;
  }
  .selectable-button.selected{
    background-color: var(--blue);
    color: #fff;
    /* border: 1p solid #939393; */
  }




  :root {
    --PhoneInput-color--focus: #03b2cb;
    --PhoneInputInternationalIconPhone-opacity: 0.8;
    --PhoneInputInternationalIconGlobe-opacity: 0.65;
    --PhoneInputCountrySelect-marginRight: 0.35em;
    --PhoneInputCountrySelectArrow-width: 0.3em;
    --PhoneInputCountrySelectArrow-marginLeft: var(
      --PhoneInputCountrySelect-marginRight
    );
    --PhoneInputCountrySelectArrow-borderWidth: 1px;
    --PhoneInputCountrySelectArrow-opacity: 0.45;
    --PhoneInputCountrySelectArrow-color: inherit;
    --PhoneInputCountrySelectArrow-color--focus: var(--PhoneInput-color--focus);
    --PhoneInputCountrySelectArrow-transform: rotate(45deg);
    --PhoneInputCountryFlag-aspectRatio: 1.5;
    --PhoneInputCountryFlag-height: 1em;
    --PhoneInputCountryFlag-borderWidth: 1px;
    --PhoneInputCountryFlag-borderColor: rgba(0, 0, 0, 0.5);
    --PhoneInputCountryFlag-borderColor--focus: var(--PhoneInput-color--focus);
    --PhoneInputCountryFlag-backgroundColor--loading: rgba(0, 0, 0, 0.1);
  }
  
  .PhoneInput {
    /* This is done to stretch the contents of this component. */
    display: flex;
    align-items: center;
    /* padding-top: 20px; */
    /* padding-left: 15px; */
    width: auto;
  }
  
  .PhoneInputInput {
    /* The phone number input stretches to fill all empty space */
    flex: 1;
    /* border: none; */
  
    /* The phone number input should shrink
           to make room for the extension input */
  
    /* min-width: 0; */
    /* height: 3rem; */
    /* padding-left: 1.0625rem; */
    /* font-family: OpenSans, sans-serif; */
    /* font-weight: 400; */
    /* position: relative; */
    /* left:100px; */
    /* font-style: normal; */
    /* font-size: 1.125rem; */
    /* color: #484553; */
    /* background-color: #f4f3f3; */
    /* border-radius: 1px; */
    /* border: 0.5px solid rgb(206, 205, 205); */
  }
  
  .PhoneInputCountryIcon {
    width: calc(
      var(--PhoneInputCountryFlag-height) *
        var(--PhoneInputCountryFlag-aspectRatio)
    );
    height: var(--PhoneInputCountryFlag-height);
  }
  
  .PhoneInputCountryIcon--square {
    width: var(--PhoneInputCountryFlag-height);
  }
  
  .PhoneInputCountryIcon--border {
    /* Removed `background-color` because when an `<img/>` was still loading
           it would show a dark gray rectangle. */
    /* For some reason the `<img/>` is not stretched to 100% width and height
           and sometime there can be seen white pixels of the background at top and bottom. */
    background-color: var(--PhoneInputCountryFlag-backgroundColor--loading);
    /* Border is added via `box-shadow` because `border` interferes with `width`/`height`. */
    /* For some reason the `<img/>` is not stretched to 100% width and height
           and sometime there can be seen white pixels of the background at top and bottom,
           so an additional "inset" border is added. */
    box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth)
        var(--PhoneInputCountryFlag-borderColor),
      inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth)
        var(--PhoneInputCountryFlag-borderColor);
  }
  
  .PhoneInputCountryIconImg {
    /* Fixes weird vertical space above the flag icon. */
    /* https://gitlab.com/catamphetamine/react-phone-number-input/-/issues/7#note_348586559 */
    display: block;
    /* 3rd party <SVG/> flag icons won't stretch if they have `width` and `height`.
           Also, if an <SVG/> icon's aspect ratio was different, it wouldn't fit too. */
    width: 100%;
    height: 100%;
  }
  .terms-condition{
    font-weight: 400;

  }
  .contact-you{
    font-weight: 400;
    color: grey;
  }
  
  .PhoneInputInternationalIconPhone {
    opacity: var(--PhoneInputInternationalIconPhone-opacity);
  }
  
  .PhoneInputInternationalIconGlobe {
    opacity: var(--PhoneInputInternationalIconGlobe-opacity);
  }
  
  /* Styling native country `<select/>`. */
  
  .PhoneInputCountry {
    position: relative;
    align-self: stretch;
    display: flex;
    align-items: center;
    margin-right: var(--PhoneInputCountrySelect-marginRight);
  }
  
  .PhoneInputCountrySelect {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    border: 0;
    opacity: 0;
    cursor: pointer;
  }
  
  .PhoneInputCountrySelect[disabled] {
    cursor: default;
  }
  
  .PhoneInputCountrySelectArrow {
    display: block;
    content: "";
    width: var(--PhoneInputCountrySelectArrow-width);
    height: var(--PhoneInputCountrySelectArrow-width);
    margin-left: var(--PhoneInputCountrySelectArrow-marginLeft);
    border-style: solid;
    border-color: var(--PhoneInputCountrySelectArrow-color);
    border-top-width: 0;
    border-bottom-width: var(--PhoneInputCountrySelectArrow-borderWidth);
    border-left-width: 0;
    border-right-width: var(--PhoneInputCountrySelectArrow-borderWidth);
    transform: var(--PhoneInputCountrySelectArrow-transform);
    opacity: var(--PhoneInputCountrySelectArrow-opacity);
  }
  
  .PhoneInputCountrySelect:focus
    + .PhoneInputCountryIcon
    + .PhoneInputCountrySelectArrow {
    opacity: 1;
    color: var(--PhoneInputCountrySelectArrow-color--focus);
  }
  
  .PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
    box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth)
        var(--PhoneInputCountryFlag-borderColor--focus),
      inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth)
        var(--PhoneInputCountryFlag-borderColor--focus);
  }
  
  .PhoneInputCountrySelect:focus
    + .PhoneInputCountryIcon
    .PhoneInputInternationalIconGlobe {
    opacity: 1;
    color: var(--PhoneInputCountrySelectArrow-color--focus);
  }

  button {
    background-color:var(--green);
    border: none;
    border-radius: 15px;
    color: black;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
  }
  
  /* button::after {
    content: ">";
    display: inline-block;
    margin-left: 8px;
  } */
  
  button:hover {
    background-color:var(--green);
  }
  
  button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }

  
  
 .completion-rate {
  position: relative;
margin: 20px;


  /* display: inline-block; */

  
}

.circle {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 8px;
  background-color: #e0e0e0;
}

.filled {
  animation: fill 0.5s forwards;
  background-color: var(--green);
}

.percentage {
  display: inline-block;
  font-size: 14px;
  color: #999;
  margin-left: 8px;
}

@keyframes fill {
  from { width: 0; }
  to { width: 12px; }
}

.footer-heading{
  width: 100%;
justify-content: space-between;

display: flex;
}
  
.survey-container {
  display: flex;

 
width: 100%;
}

.survey-info {
  font-size: 2em;
  font-weight: bold;
  animation: fade-in 1s ease-out;
  width: 40%;
  
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}


.survey-image {
  width: 50%;
}

img {
  max-width: 150%;
  height: auto;
}
.choices-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  
}
