.lisiting-header{
   margin-left: 10%; 
}
.sub-header{
    color: gray;
    letter-spacing: 1px;
}
.price-listing{
    display: flex;
    justify-content: center;
    gap: 5%;
    margin-left: 13%;
    /* align-items: center; */
}

.plan-one{
    height: 20%;
    width: 15%;
    background-color:#DBD8FC;
    text-align: center;
    border: 2px solid #ddd; /* Add a border */
    border-radius: 10px;
}
.plan-two{
    height: 20%;
    width: 15%;
    background-color: #FFEEC3;
    text-align: center;
    border: 2px solid #ddd; /* Add a border */
    border-radius: 10px;
}
.plan-two button {
    background-color: black; /* Green background color for the button */
    color: white; /* White text color for the button */
    padding: 10px 20px; /* Add padding to the button */
    border: none; /* Remove default button border */
    border-radius: 5px; /* Add rounded corners to the button */
    cursor: pointer; /* Add a pointer cursor to the button */
}


.plan-one button {
    background-color: black; /* Green background color for the button */
    color: white; /* White text color for the button */
    padding: 10px 20px; /* Add padding to the button */
    border: none; /* Remove default button border */
    border-radius: 5px; /* Add rounded corners to the button */
    cursor: pointer; /* Add a pointer cursor to the button */
}

.plan-three{
    height: 20%;
    width: 15%;
    background-color: #D8F3FC;
    text-align: center;
    border: 2px solid #ddd; /* Add a border */
    border-radius: 10px;
}
.plan-three button {
    background-color: black; /* Green background color for the button */
    color: white; /* White text color for the button */
    padding: 10px 20px; /* Add padding to the button */
    border: none; /* Remove default button border */
    border-radius: 5px; /* Add rounded corners to the button */
    cursor: pointer; /* Add a pointer cursor to the button */
}
.plan-free{
    height: 20%;
    width: 15%;
    background-color: #E1FCD8;
    text-align: center;
    border: 2px solid #ddd; /* Add a border */
    border-radius: 10px;
}

.plan-free button {
    background-color: black; /* Green background color for the button */
    color: white; /* White text color for the button */
    padding: 10px 20px; /* Add padding to the button */
    border: none; /* Remove default button border */
    border-radius: 5px; /* Add rounded corners to the button */
    cursor: pointer; /* Add a pointer cursor to the button */
}
.table-container {
    margin-top: 3%;
    max-height: 600px; /* Set the maximum height for the scrollable area */
    overflow-y: auto; /* Enable vertical scrolling */
  }
  
  table {
    width: 95%;
    border-collapse: collapse;
   
  }
  
  td {
    padding-right: 155px;
    color:gray;

  }
.black-text{
    color: black;
}
  
@media screen and (min-width: 768px) and (max-width: 1023px) {
    .list-overall-container {
        overflow-x: auto;
        white-space: nowrap;
      }
      
     
      .price-listing{
        margin-right: 20%;
        width: 90%;
        overflow-x: auto; /* Enable horizontal scrolling for the entire container */
        justify-content: flex-start;
  white-space: nowrap;
      }
    .plan-one{
        width: 36%;
    }
    .plan-two{
        width: 36%;
    }
    .plan-three{
        width: 36%;
    }
    .plan-free{
        width: 36%;
    }
      .table-container {
        overflow-x: auto;
      }
      
      /* Optional: Style adjustments for better visibility */
    
      
      /* Optional: Adjustments for better visibility in the table */
      .table-container table {
        border-collapse: collapse;
      }
      
      .table-container td {
        border: 1px solid #ddd;
        padding: 10px;
        text-align: center;
      }
      
}
@media screen and (max-width: 767px){
    .list-overall-container {
        overflow-x: auto;
        white-space: nowrap;
      }
      
      .plan-one{
        width: 36%;
    }
    .plan-two{
        width: 36%;
    }
    .plan-three{
        width: 36%;
    }
    .plan-free{
        width: 36%;
    }
      .price-listing{
       margin-right: 20%;
        width: 90%;
        overflow-x: auto; /* Enable horizontal scrolling for the entire container */
  white-space: nowrap;
  justify-content: flex-start;
      }
      
      
      .table-container {
        overflow-x: auto;
      }
    
      /* Optional: Style adjustments for better visibility */
    
      
      /* Optional: Adjustments for better visibility in the table */
      .table-container table {
        border-collapse: collapse;
      }
      
      .table-container td {
        border: 1px solid #ddd;
        padding: 10px;
        text-align: center;
      }
      .lisiting-header{
        min-width: 30%;
      }

}

